var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { domainService } from "~/config/server";
import { NetService } from "~/utils/net.service";
import { Inject, Debounce } from "~/core/decorator";
import { Service } from "~/core/service";
import { FilterService } from "~/utils/filter.service";
var DistributeCaseService = /** @class */ (function (_super) {
    __extends(DistributeCaseService, _super);
    function DistributeCaseService() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * 地区分案统计查询
     */
    DistributeCaseService.prototype.searchAreaCountDisCase = function (data, page, sort, loading) {
        var model = Object.assign({}, data);
        var delegationDate = FilterService.dateRanageFormat(model.delegationDate, "yyyy-MM-dd");
        var endCaseDate = FilterService.dateRanageFormat(model.endCaseDate, "yyyy-MM-dd");
        return this.netService.send({
            server: domainService.distributeCaseController.searchAreaCountDisCase,
            data: {
                principalId: model.principalId,
                casePool: model.casePool,
                batchNumber: model.batchNumber,
                delegationDateStart: delegationDate.start,
                delegationDateEnd: delegationDate.end,
                endCaseDateStart: endCaseDate.start,
                endCaseDateEnd: endCaseDate.end,
            },
            page: page,
            sort: sort,
            loading: loading,
        });
    };
    /**
     * 个人分案统计查询
     */
    DistributeCaseService.prototype.searchPersonalCountDisCase = function (data, page, sort, loading) {
        var model = Object.assign({}, data);
        var delegationDate = FilterService.dateRanageFormat(model.delegationDate, "yyyy-MM-dd");
        var endCaseDate = FilterService.dateRanageFormat(model.endCaseDate, "yyyy-MM-dd");
        return this.netService.send({
            server: domainService.distributeCaseController.searchPersonalCountDisCase,
            data: {
                principalId: model.principalId,
                casePool: model.casePool,
                batchNumber: model.batchNumber,
                delegationDateStart: delegationDate.start,
                delegationDateEnd: delegationDate.end,
                endCaseDateStart: endCaseDate.start,
                endCaseDateEnd: endCaseDate.end,
            },
            page: page,
            sort: sort,
            loading: loading,
        });
    };
    /**
     * 地区分案明细查询
     * @param data
     * @param page
     * @param sort
     * @param loading
     */
    DistributeCaseService.prototype.searchDetalCountDisCase = function (data, page, sort, loading) {
        var delegationDate = FilterService.dateRanageFormat(data.delegationDate, "yyyy-MM-dd");
        var endCaseDate = FilterService.dateRanageFormat(data.endCaseDate, "yyyy-MM-dd");
        return this.netService.send({
            server: domainService.distributeCaseController.searchDetalCountDisCase,
            data: {
                principalId: data.principalId,
                casePool: data.casePool,
                phone: data.phone,
                batchNumber: data.batchNumber,
                deptCode: data.departCode,
                personalName: data.personalName,
                certificateNo: data.certificateNo,
                city: data.city,
                handsNumber: data.handsNumber,
                delegationDateStart: delegationDate.start,
                delegationDateEnd: delegationDate.end,
                endCaseDateStart: endCaseDate.start,
                endCaseDateEnd: endCaseDate.end,
                leftAmtStart: data.leftAmt.min,
                leftAmtEnd: data.leftAmt.max,
                overduePeriodsStart: data.overduePeriods.min,
                overduePeriodsEnd: data.overduePeriods.max,
                overdueDaysStart: data.overdueDays.min,
                overdueDaysEnd: data.overdueDays.max,
                cardNo: data.cardNo,
                address: data.address,
                employerName: data.employerName,
                delegationMonth: data.delegationMonth,
                cardNoType: data.cardNoType,
                unhealthyFlag: data.unhealthyFlag,
                receiveName: data.receiveName,
                batchNo: data.batchNo,
                extensionInfo: data.extensionInfo,
                extensionAgent: data.extensionAgent,
                retireCaseDays: data.retireCaseDays,
                account: data.account,
            },
            page: page,
            sort: sort,
            loading: loading,
        });
    };
    /**
     * 个人分案明细查询
     */
    DistributeCaseService.prototype.searchDetalPersonalDisCase = function (data, page, sort, loading) {
        var delegationDate = FilterService.dateRanageFormat(data.delegationDate, "yyyy-MM-dd");
        var endCaseDate = FilterService.dateRanageFormat(data.endCaseDate, "yyyy-MM-dd");
        return this.netService.send({
            server: domainService.distributeCaseController.searchDetalPersonalDisCase,
            data: {
                casePool: data.casePool,
                batchNumber: data.batchNumber,
                deptCode: data.departCode,
                principalId: data.principalId,
                phone: data.phone,
                userName: data.userName,
                currentCollectorName: data.currentCollectorName,
                personalName: data.personalName,
                certificateNo: data.certificateNo,
                city: data.city,
                handsNumber: data.handsNumber,
                delegationDateStart: delegationDate.start,
                delegationDateEnd: delegationDate.end,
                endCaseDateStart: endCaseDate.start,
                endCaseDateEnd: endCaseDate.end,
                leftAmtStart: data.leftAmt.min,
                leftAmtEnd: data.leftAmt.max,
                overduePeriodsStart: data.overduePeriods.min,
                overduePeriodsEnd: data.overduePeriods.max,
                overdueDaysStart: data.overdueDays.min,
                overdueDaysEnd: data.overdueDays.max,
                cardNo: data.cardNo,
                address: data.address,
                employerName: data.employerName,
                delegationMonth: data.delegationMonth,
                cardNoType: data.cardNoType,
                unhealthyFlag: data.unhealthyFlag,
                receiveName: data.receiveName,
                batchNo: data.batchNo,
                extensionInfo: data.extensionInfo,
                extensionAgent: data.extensionAgent,
                retireCaseDays: data.retireCaseDays,
                account: data.account,
            },
            page: page,
            sort: sort,
            loading: loading,
        });
    };
    /**
     * 案件下发
     * @param data 实体对象
     */
    DistributeCaseService.prototype.issuedCase = function (data, loading) {
        return this.netService.send({
            server: domainService.distributeCaseController.issuedCase,
            data: data,
            loading: loading,
        });
    };
    /**
     * 个人案件确认下发
     */
    DistributeCaseService.prototype.issuedPersonalCase = function (data, loading) {
        return this.netService.send({
            server: domainService.distributeCaseController.issuedPersonalCase,
            data: {
                batchNumber: data.batchNumber,
                caseIds: data.caseIds,
                type: data.type,
            },
            loading: loading,
        });
    };
    /**
     * 根据案件标识获取对应的批次号
     */
    DistributeCaseService.prototype.getBatchNumberByCaseIssuedFlag = function (data, loading) {
        return this.netService.send({
            server: domainService.distributeCaseController.getBatchNumberByCaseIssuedFlag,
            data: data,
            loading: loading,
        });
    };
    /**
     * 根据案件标识获取对应的批次号
     */
    DistributeCaseService.prototype.getBatchNumberByCaseIssued = function (caseIssuedFlag, string, loading) {
        return this.netService.send({
            server: domainService.distributeCaseController.getBatchNumberByCaseIssuedFlag,
            data: {
                request: caseIssuedFlag,
                principalId: string,
            },
            loading: loading,
        });
    };
    /**
     *
     * @param batchNumber 批次号
     * @param issuedFlag 案件状态
     */
    DistributeCaseService.prototype.getCaseCommonPro = function (batchNumber, issuedFlag) {
        return this.netService.send({
            server: domainService.distributeCaseController.getCaseCommonPro,
            data: {
                batchNumber: batchNumber,
                issuedFlag: issuedFlag,
            },
            loading: true,
        });
    };
    /**
     * 根据条件获取案件数量及金额
     * @param queryModel
     */
    DistributeCaseService.prototype.getBaseCaseNumberAndAmt = function (queryModel, loading) {
        return this.netService.send({
            server: domainService.distributeCaseController.getBaseCaseNumberAndAmt,
            data: queryModel,
            loading: loading,
        });
    };
    /**
     * 地区批量下发
     * @param model
     */
    DistributeCaseService.prototype.issuedCaseBatch = function (model, loading) {
        return this.netService.send({
            server: domainService.distributeCaseController.issuedCaseBatch,
            data: model,
            loading: loading,
        });
    };
    /**
     * 个人批量下发
     * @param model
     */
    DistributeCaseService.prototype.issuedPersonalCaseBatch = function (model, loading) {
        return this.netService.send({
            server: domainService.distributeCaseController.issuedPersonalCaseBatch,
            data: model,
            loading: loading,
        });
    };
    __decorate([
        Inject(NetService)
    ], DistributeCaseService.prototype, "netService", void 0);
    __decorate([
        Debounce()
    ], DistributeCaseService.prototype, "issuedCaseBatch", null);
    __decorate([
        Debounce()
    ], DistributeCaseService.prototype, "issuedPersonalCaseBatch", null);
    return DistributeCaseService;
}(Service));
export { DistributeCaseService };

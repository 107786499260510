var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { managementService } from "~/config/server";
import { NetService } from "~/utils/net.service";
import { Inject, Debounce } from "~/core/decorator";
import { Service } from "~/core/service";
import { FilterService } from "~/utils/filter.service";
var OrganizationService = /** @class */ (function (_super) {
    __extends(OrganizationService, _super);
    function OrganizationService() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /*
     *获取所有组织机构
     */
    OrganizationService.prototype.findAllOrganization = function (loading) {
        return this.netService.send({
            server: managementService.organizationController.findAllOrganization,
            loading: loading,
        });
    };
    /**
     * 获取所有组织的父/子机构
     */
    OrganizationService.prototype.findParentOrSon = function (data, loading) {
        return this.netService.send({
            server: managementService.organizationController.findParentOrSon,
            data: {
                orgId: data.orgId,
            },
            loading: loading,
        });
    };
    /**
     * 创建获取组织机构
     */
    OrganizationService.prototype.createOrganization = function (data) {
        return this.netService.send({
            server: managementService.organizationController.createOrganization,
            data: {
                addressText: data.addressText,
                contactPerson: data.contactPerson,
                name: data.name,
                parent: data.parent,
                phone: data.phone,
                type: data.type,
                level: data.level,
                state: data.state,
                collectionOrganization: data.collectionOrganization,
                accountPrefix: data.accountPrefix,
                entrustCaseCity: data.entrustCaseCity,
            },
            loading: true,
        });
    };
    /**
     * 修改获取组织机构
     */
    OrganizationService.prototype.modifyOrganization = function (data) {
        return this.netService.send({
            server: managementService.organizationController.modifyOrganization,
            data: {
                id: data.id,
                addressText: data.addressText,
                contactPerson: data.contactPerson,
                name: data.name,
                parent: data.parent,
                phone: data.phone,
                type: data.type,
                level: data.level,
                state: data.state,
                collectionOrganization: data.collectionOrganization,
                accountPrefix: data.accountPrefix,
                entrustCaseCity: data.entrustCaseCity,
            },
            loading: true,
        });
    };
    /**
     * 删除获取组织机构
     */
    OrganizationService.prototype.deleteOrganization = function (id) {
        return this.netService.send({
            server: managementService.organizationController.deleteOrganization,
            append: [id],
            loading: true,
        });
    };
    /**
     * 根据组织机构id获取对应用户
     */
    OrganizationService.prototype.queryByOrganizationId = function (data, page, sort) {
        return this.netService.send({
            server: managementService.organizationController.queryByOrganizationId,
            data: data,
            page: page,
            sort: sort,
            loading: true,
        });
    };
    /**
     * 获取所有的分公司
     */
    OrganizationService.prototype.findAllBranch = function () {
        return this.netService.send({
            server: managementService.organizationController.findAllBranch,
            loading: true,
        });
    };
    /**
     * 根据组织机构id获取对应员工
     */
    OrganizationService.prototype.queryUserByOrganizationId = function (data, page) {
        var birthday = FilterService.dateRanageFormat(data.birthday, "yyyy-MM-dd");
        var createTime = FilterService.dateRanageFormat(data.createTime, "yyyy-MM-dd");
        var resignationDate = FilterService.dateRanageFormat(data.resignationDate, "yyyy-MM-dd");
        var signDate = FilterService.dateRanageFormat(data.signDate, "yyyy-MM-dd");
        var endDate = FilterService.dateRanageFormat(data.endDate, "yyyy-MM-dd");
        return this.netService.send({
            server: managementService.organizationController.queryUserByOrganizationId,
            data: {
                fullName: data.fullName,
                userName: data.userName,
                status: data.status,
                organizationId: data.organizationId,
                callPhone: data.callPhone,
                phone: data.phone,
                political: data.political,
                experience: data.experience,
                education: data.education,
                major: data.major,
                station: data.station,
                workUnit: data.workUnit,
                birthdayStart: birthday.start,
                birthdayEnd: birthday.end,
                createTimeStart: createTime.start,
                createTimeEnd: createTime.end,
                resignationDateStart: resignationDate.start,
                resignationDateEnd: resignationDate.end,
                signDateStart: signDate.start,
                signDateEnd: signDate.end,
                endDateStart: endDate.start,
                endDateEnd: endDate.end,
                isShowAll: data.isShowAll,
                idCard: data.idCard,
            },
            page: page,
            loading: true,
        });
    };
    /**
     *
     * @param loading
     */
    OrganizationService.prototype.getAllHighBranch = function () {
        return this.netService.send({
            server: managementService.organizationController.getAllHighBranch,
            loading: true,
        });
    };
    /**
     * 查询机构下的用户 不分页
     */
    OrganizationService.prototype.queryOperatorByOrgId = function (orgId) {
        return this.netService.send({
            server: managementService.organizationController.queryOperatorByOrgId,
            append: [orgId],
        });
    };
    /**
     * 获取当前登陆人下的调收员
     */
    OrganizationService.prototype.queryUserByDepartId = function () {
        return this.netService.send({
            server: managementService.organizationController.queryUserByDepartId,
        });
    };
    /**
     * 查询可以选择的操作员信息
     */
    OrganizationService.prototype.findChooseOperatorList = function () {
        return this.netService.send({
            server: managementService.organizationController.findChooseOperatorList,
        });
    };
    __decorate([
        Inject(NetService)
    ], OrganizationService.prototype, "netService", void 0);
    __decorate([
        Debounce()
    ], OrganizationService.prototype, "findAllOrganization", null);
    return OrganizationService;
}(Service));
export { OrganizationService };

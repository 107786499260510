var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import { Prop, Watch, Emit } from "vue-property-decorator";
import Component from "vue-class-component";
import DataTreeNode from "~/components/common/data-tree-node.vue";
import { EditType } from "~/config/enum.framework";
var DataTree = /** @class */ (function (_super) {
    __extends(DataTree, _super);
    function DataTree() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.nodeMap = new Map();
        return _this;
    }
    DataTree.prototype.onDataChange = function (data) { };
    DataTree.prototype.emitSelectChange = function (data) { };
    DataTree.prototype.emitCurrentCheckedChange = function (id, value) { };
    DataTree.prototype.emitEdit = function (data) { };
    DataTree.prototype.addEdit = function (data) { };
    DataTree.prototype.deleteEdit = function (data) { };
    DataTree.prototype.moveUp = function (data) { };
    DataTree.prototype.moveDown = function (data) { };
    Object.defineProperty(DataTree.prototype, "propsObject", {
        get: function () {
            var propObj = {
                title: "title"
            };
            if (this.props) {
                propObj = __assign(__assign({}, propObj), this.props);
            }
            return propObj;
        },
        enumerable: false,
        configurable: true
    });
    DataTree.prototype.registerNode = function (node, data) {
        this.nodeMap.set(node, data);
    };
    Object.defineProperty(DataTree.prototype, "selected", {
        /**
         * 获取当前选中项
         */
        get: function () {
            return this.currentNode;
        },
        /**
         * 选择当前选中项
         */
        set: function (node) {
            if (this.currentNode !== node) {
                // 清空历史选中项
                if (this.currentNode) {
                    this.currentNode.selected = false;
                }
                this.currentNode = node;
                this.emitSelectChange(node.data);
            }
        },
        enumerable: false,
        configurable: true
    });
    /**
     * 获取选中key
     */
    DataTree.prototype.getCheckedKeys = function () {
        return Array.from(this.nodeMap.entries())
            .filter(function (_a) {
            var node = _a[0], data = _a[1];
            return node.checked;
        })
            .map(function (_a) {
            var node = _a[0], data = _a[1];
            return data.id;
        });
    };
    /**
     * 展开树的第一层节点
     */
    DataTree.prototype.expandedFirstNode = function () {
        var it = Array[0] || [];
        var key = it[0] || {};
        key.expanded = true;
    };
    __decorate([
        Prop({
            default: function () { return []; }
        })
    ], DataTree.prototype, "data", void 0);
    __decorate([
        Prop({
            default: function () { },
            type: Object
        })
    ], DataTree.prototype, "props", void 0);
    __decorate([
        Prop({
            default: false,
            type: Boolean
        })
    ], DataTree.prototype, "showCheckbox", void 0);
    __decorate([
        Prop({
            type: Boolean,
            default: false
        })
    ], DataTree.prototype, "showEdit", void 0);
    __decorate([
        Prop({
            type: Array,
            default: function () { return [EditType.ADD, EditType.MODIFY, EditType.DELETE]; }
        })
    ], DataTree.prototype, "editConfig", void 0);
    __decorate([
        Prop({
            type: Function
        })
    ], DataTree.prototype, "loadData", void 0);
    __decorate([
        Watch("data")
    ], DataTree.prototype, "onDataChange", null);
    __decorate([
        Emit("select-change")
    ], DataTree.prototype, "emitSelectChange", null);
    __decorate([
        Emit("current-checked-change")
    ], DataTree.prototype, "emitCurrentCheckedChange", null);
    __decorate([
        Emit('edit')
    ], DataTree.prototype, "emitEdit", null);
    __decorate([
        Emit('add')
    ], DataTree.prototype, "addEdit", null);
    __decorate([
        Emit('delete')
    ], DataTree.prototype, "deleteEdit", null);
    __decorate([
        Emit('moveUp')
    ], DataTree.prototype, "moveUp", null);
    __decorate([
        Emit('moveDown')
    ], DataTree.prototype, "moveDown", null);
    DataTree = __decorate([
        Component({
            name: "data-tree",
            components: {
                DataTreeNode: DataTreeNode
            }
        })
    ], DataTree);
    return DataTree;
}(Vue));
export default DataTree;

import { render, staticRenderFns } from "./user-case-batch-allot.vue?vue&type=template&id=3ba8afe4&scoped=true"
import script from "./user-case-batch-allot.vue?vue&type=script&lang=ts"
export * from "./user-case-batch-allot.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3ba8afe4",
  null
  
)

export default component.exports
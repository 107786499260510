var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { dataimpService } from '~/config/server';
import { NetService } from '~/utils/net.service';
import { Inject, Debounce } from "~/core/decorator";
var CaseDistributeService = /** @class */ (function () {
    function CaseDistributeService() {
    }
    /**
     * 区域分案预览
     */
    CaseDistributeService.prototype.areaCaseDistributePre = function (data, loading) {
        return this.netService.send({
            server: dataimpService.caseDistributeController.areaCaseDistributePre,
            data: data,
            loading: loading
        });
    };
    /**
     * 区域分案确认
     */
    CaseDistributeService.prototype.areaConfirmDistributeCase = function (data, loading) {
        return this.netService.send({
            server: dataimpService.caseDistributeController.areaConfirmDistributeCase,
            data: data,
            loading: loading
        });
    };
    /**
     * 区域策略方案
     */
    CaseDistributeService.prototype.strategyDistributeCase = function (data, loading) {
        return this.netService.send({
            server: dataimpService.caseDistributeController.strategyDistributeCase,
            data: data,
            loading: loading
        });
    };
    /**
     * 个人分案预览
     */
    CaseDistributeService.prototype.groupCaseDistributePre = function (data, loading) {
        return this.netService.send({
            server: dataimpService.caseDistributeController.groupCaseDistributePre,
            data: data,
            loading: loading
        });
    };
    /**
     * 个人分案确认
     */
    CaseDistributeService.prototype.groupConfirmDistributeCase = function (data, loading) {
        return this.netService.send({
            server: dataimpService.caseDistributeController.groupConfirmDistributeCase,
            data: data,
            loading: loading
        });
    };
    /**
     * 分配展示
     */
    CaseDistributeService.prototype.assistCaseDistributePre = function (data, loading) {
        return this.netService.send({
            server: dataimpService.caseDistributeController.assistCaseDistributePre,
            data: data,
            loading: loading
        });
    };
    /**
     * 分配确认
     */
    CaseDistributeService.prototype.assistConfirmDistributeCase = function (data, loading) {
        return this.netService.send({
            server: dataimpService.caseDistributeController.assistConfirmDistributeCase,
            data: data,
            loading: loading
        });
    };
    /**
     * 区域分案
     * @param model
     */
    CaseDistributeService.prototype.areaCaseBatchDistribute = function (data) {
        return this.netService.send({
            server: dataimpService.caseDistributeController.areaCaseBatchDistribute,
            data: data,
            loading: true
        });
    };
    /**
     * 地区批量分配中确认
     * @param model
     */
    CaseDistributeService.prototype.areaConfirmBatchDistribute = function (data, loading) {
        return this.netService.send({
            server: dataimpService.caseDistributeController.areaConfirmBatchDistribute,
            data: data,
            loading: loading
        });
    };
    /**
     * 个人分案预览
     * @param model
     */
    CaseDistributeService.prototype.groupCaseBatchDistribute = function (data, loading) {
        return this.netService.send({
            server: dataimpService.caseDistributeController.groupCaseBatchDistribute,
            data: data,
            loading: loading
        });
    };
    /**
     * 个人批量分配中确认
     * @param model
     */
    CaseDistributeService.prototype.groupBatchConfirmDistribute = function (data, loading) {
        return this.netService.send({
            server: dataimpService.caseDistributeController.groupBatchConfirmDistribute,
            data: data,
            loading: loading
        });
    };
    /**
     * 个案手动分案预览
     * @param model
     */
    CaseDistributeService.prototype.groupCaseDistributeRenew = function (data, loading) {
        return this.netService.send({
            server: dataimpService.caseDistributeController.groupCaseDistributeRenew,
            data: data,
            loading: loading
        });
    };
    /**
     * 个案手动分配中确认
     * @param model
     */
    CaseDistributeService.prototype.groupConfirmCaseDistributeRenew = function (data, loading) {
        return this.netService.send({
            server: dataimpService.caseDistributeController.groupConfirmCaseDistributeRenew,
            data: data,
            loading: loading
        });
    };
    /**
     * 个案批次分案预览
     * @param model
     */
    CaseDistributeService.prototype.groupCaseBatchRenew = function (data, loading) {
        return this.netService.send({
            server: dataimpService.caseDistributeController.groupCaseBatchRenew,
            data: data,
            loading: loading
        });
    };
    /**
     * 个案批量分配中确认
     * @param model
     */
    CaseDistributeService.prototype.groupBatchConfirmDistributeRenew = function (data, loading) {
        return this.netService.send({
            server: dataimpService.caseDistributeController.groupBatchConfirmDistributeRenew,
            data: data,
            loading: loading
        });
    };
    /**
     * 个案批量分配中确认
     * @param model
     */
    CaseDistributeService.prototype.checkCustomerInDiffArea = function (data, loading) {
        return this.netService.send({
            server: dataimpService.caseDistributeController.checkCustomerInDiffArea,
            data: data,
            loading: loading
        });
    };
    __decorate([
        Inject(NetService)
    ], CaseDistributeService.prototype, "netService", void 0);
    __decorate([
        Debounce()
    ], CaseDistributeService.prototype, "areaCaseDistributePre", null);
    __decorate([
        Debounce()
    ], CaseDistributeService.prototype, "areaConfirmDistributeCase", null);
    __decorate([
        Debounce()
    ], CaseDistributeService.prototype, "groupCaseDistributePre", null);
    __decorate([
        Debounce()
    ], CaseDistributeService.prototype, "groupConfirmDistributeCase", null);
    __decorate([
        Debounce()
    ], CaseDistributeService.prototype, "assistCaseDistributePre", null);
    __decorate([
        Debounce()
    ], CaseDistributeService.prototype, "assistConfirmDistributeCase", null);
    __decorate([
        Debounce()
    ], CaseDistributeService.prototype, "areaCaseBatchDistribute", null);
    __decorate([
        Debounce()
    ], CaseDistributeService.prototype, "areaConfirmBatchDistribute", null);
    __decorate([
        Debounce()
    ], CaseDistributeService.prototype, "groupCaseBatchDistribute", null);
    __decorate([
        Debounce()
    ], CaseDistributeService.prototype, "groupBatchConfirmDistribute", null);
    __decorate([
        Debounce()
    ], CaseDistributeService.prototype, "groupCaseDistributeRenew", null);
    __decorate([
        Debounce()
    ], CaseDistributeService.prototype, "groupConfirmCaseDistributeRenew", null);
    __decorate([
        Debounce()
    ], CaseDistributeService.prototype, "groupCaseBatchRenew", null);
    __decorate([
        Debounce()
    ], CaseDistributeService.prototype, "groupBatchConfirmDistributeRenew", null);
    return CaseDistributeService;
}());
export { CaseDistributeService };
